<template>
  <div
    @click.self="openModal(modalData)"
    class="multimedia__wrapper"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <slot />
    <footer-media
      class="footer__media"
      :icon="icon"
      :title="title"
      :edit="isEdit"
      :iconColor="hover ? 'secondary' : ''"
      @edit-option="editMedia(media.id)"
      @download="downloadMedia(media, token)"
      @delete="deleteMedia(media.id)"
      @open-modal="openModal(modalData)"
    />
  </div>
</template>

<script>
import { getToken } from '@/vue-apollo';
import downloadMedia from '@/utils/downloadMedia';


export default {
  components: {
    FooterMedia: () => import('./FooterMedia.vue'),
  },
  data() {
    return {
      hover: false,
      token: getToken(),

    };
  },
  props: {
    media: {
      type: Object,
    },
    mediaType: {
      type: String,
      required: true,
    },
    deleteMedia: {
      type: Function,
    },
    editMedia: {
      type: Function,
    },
    openModal: {
      type: Function,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.mediaType !== 'location' ? this.media[this.mediaType].filename : this.media[this.mediaType].name;
    },
    icon() {
      if (this.mediaType !== 'location') {
        return this.mediaType === 'image' ? 'image-icon' : 'video-icon';
      }
      return 'map-marker-icon';
    },
    modalData() {
      return this.mediaType !== 'location' ? this.media[this.mediaType].url : this.media.location;
    },
  },
  methods: {
    downloadMedia,
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_multimedia-tab-databank.scss";
</style>
